import PersonPinCircleTwoToneIcon from '@mui/icons-material/PersonPinCircleTwoTone';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { Formik } from 'formik';
import sortBy from 'lodash/sortBy';
import React from 'react';
import * as Yup from 'yup';

import { MarketRead } from '@headway/api/models/MarketRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserRead } from '@headway/api/models/UserRead';
import { UserApi } from '@headway/api/resources/UserApi';
import { useMediaQuery } from '@headway/helix/utils';
import { logException } from '@headway/shared/utils/sentry';
import { Button } from '@headway/ui/Button';
import { FieldAutocomplete } from '@headway/ui/form/FieldAutocomplete';
import { FieldControl } from '@headway/ui/form/FieldControl';
import { FieldErrorText } from '@headway/ui/form/FieldErrorText';
import { FieldInput } from '@headway/ui/form/FieldInput';
import { FieldInputLabel } from '@headway/ui/form/FieldInputLabel';
import { FieldSelect } from '@headway/ui/form/FieldSelect';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { Modal } from '@headway/ui/Modal';
import { useMarkets } from '@headway/ui/providers/MarketProvider';
import { theme } from '@headway/ui/theme';
import { notifyError, notifySuccess } from '@headway/ui/utils/notify';

import { getProvidersSearchPath } from '../hooks/useProvidersSearch';
import { useRouter } from '../hooks/useRouter';

type GetStartedButtonProps = {
  user: UserRead;
  ctaCopy: string;
  showStateSelect?: boolean;
  id?: string;
};

export type GetStartedButtonRef = HTMLInputElement;

export const GetStartedButton = React.forwardRef<
  GetStartedButtonRef,
  GetStartedButtonProps
>(({ user, ctaCopy, showStateSelect, id, ...rest }, ref) => {
  const { markets, marketsByState } = useMarkets();
  const router = useRouter();
  const [selectedNotLaunchedState, setSelectedNotLaunchedState] =
    React.useState<MarketRead | null>(null);

  const alphabeticalMarkets = sortBy(markets, 'displayName');

  const liveMarkets = alphabeticalMarkets.filter((m) => m.marketplaceLive);
  const otherMarkets = alphabeticalMarkets.filter((m) => !m.marketplaceLive);

  const userLastSearchedState: UnitedStates | undefined =
    user?.lastSearchedState;

  const matches = useMediaQuery(`(max-width: ${theme.breakpoints.small}px)`);

  return (
    <Formik
      initialValues={{
        market: userLastSearchedState
          ? marketsByState[userLastSearchedState]
          : undefined,
      }}
      onSubmit={async ({ market: marketValue }) => {
        if (marketValue) {
          const market =
            typeof marketValue === 'object'
              ? marketValue
              : marketsByState[marketValue];
          if (market.marketplaceLive) {
            router.push(getProvidersSearchPath({ marketSlug: market.slug }));
          } else {
            setSelectedNotLaunchedState(market);
          }
        }
      }}
    >
      {({ isSubmitting, resetForm, submitForm, setFieldValue }) => {
        return (
          <React.Fragment>
            <SafeFormikForm
              css={{
                display: 'flex',
                button: { borderRadius: '0 8px 8px 0' },
                '.MuiOutlinedInput-root': { borderRadius: '8px 0 0 8px' },
                '.MuiInput-root:before': {
                  border: 'none',
                },
                '.MuiInput-root:hover:not(.Mui-disabled):before': {
                  border: 'none',
                },
                maxWidth: '556px',
              }}
              {...rest}
            >
              <FieldControl
                fullWidth
                id={id}
                name="market"
                css={{
                  marginBottom: '0 !important',
                  '& *:focus': {
                    outline: `2px solid ${theme.color.translucentWhite} !important`,
                  },
                }}
                variant="outlined"
              >
                {matches ? (
                  <React.Fragment>
                    <FieldInputLabel variant="outlined">
                      Where are you located?
                    </FieldInputLabel>
                    <FieldSelect
                      label="Where are you located?"
                      ref={ref}
                      native
                      fullWidth
                      variant="outlined"
                      onChange={({ value }: any) => {
                        setFieldValue('market', value);
                        submitForm();
                      }}
                    >
                      <option value=""></option>
                      {liveMarkets.map((market) => (
                        <option key={market.state} value={market.state}>
                          {market.displayName}
                        </option>
                      ))}
                      {otherMarkets.length ? (
                        <>
                          <option disabled>---- Coming Soon! ----</option>
                          {otherMarkets.map((market) => (
                            <option key={market.state} value={market.state}>
                              {market.displayName}
                            </option>
                          ))}{' '}
                        </>
                      ) : null}
                    </FieldSelect>
                  </React.Fragment>
                ) : (
                  <FieldAutocomplete
                    fullWidth
                    autoHighlight
                    getOptionLabel={(market: MarketRead) => market.displayName}
                    options={liveMarkets.concat(otherMarkets)}
                    groupBy={(market: MarketRead) =>
                      !market.marketplaceLive ? 'Coming soon!' : ''
                    }
                    renderOption={(props: {}, market: MarketRead) => (
                      <li {...props}>
                        <div data-testid={`stateSlugOption-${market.slug}`}>
                          {market.displayName}
                        </div>
                      </li>
                    )}
                    filterOptions={createFilterOptions({
                      matchFrom: 'any',
                      stringify: (market: MarketRead) => market.displayName,
                    })}
                    onChange={(
                      _: any,
                      value: MarketRead | MarketRead[] | null
                    ) => {
                      // NOTE: value is being passed in as potentially an arry, since FieldAutomplete is typed as multi for now
                      setFieldValue('market', value);
                      submitForm();
                    }}
                    sx={{
                      '.MuiInput-root:before': {
                        border: 'none',
                      },
                    }}
                    clearText="Clear the entered location"
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        css={{
                          margin: 0,
                          borderRadius: '8px 0 8px 0 !important',
                        }}
                        label={
                          <div
                            css={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {!matches && (
                              <PersonPinCircleTwoToneIcon
                                sx={{
                                  color: theme.color.primary,
                                  marginRight: theme.space.sm,
                                }}
                                fontSize="inherit"
                              />
                            )}
                            <div>Where are you located?</div>
                          </div>
                        }
                        margin="normal"
                        variant="outlined"
                        ref={ref}
                      />
                    )}
                  />
                )}
              </FieldControl>
              <Button
                type="submit"
                css={{
                  flexShrink: 0,
                }}
                color="primary"
                variant="contained"
                disabled={isSubmitting}
                size="large"
                data-testid="getStartedButton"
                className={'ld-landing-page-find-providers-btn'} // for AB testing metrics
              >
                {matches ? <SearchIcon css={{ color: 'white' }} /> : ctaCopy}
              </Button>
            </SafeFormikForm>
            <Modal
              title="Let's keep in touch!"
              open={!!selectedNotLaunchedState}
              keepMounted={false}
              onClose={() => {
                resetForm();
                setSelectedNotLaunchedState(null);
              }}
            >
              <p>
                We don't work with any therapists who are licensed to practice
                in {selectedNotLaunchedState?.displayName} yet. Leave us your
                email and we will let you know as soon as we do!
              </p>
              <Formik
                initialValues={{
                  email: user?.email || user?.questionnaireEmail || '',
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email('Please provide a valid email')
                    .required('Please provide a valid email'),
                })}
                onSubmit={async ({ email }) => {
                  try {
                    await UserApi.updateUser(user.id, {
                      questionnaireEmail: email,
                    });
                    // TODO (soleil/sc-124213): find new place for this
                    // trackEvent({
                    //   name: 'Out of state email collection',
                    //   properties: {
                    //     patientUserId: user.id,
                    //     patientEmail: email,
                    //     marketRequested: selectedNotLaunchedState?.displayName,
                    //   },
                    // });
                    notifySuccess("We'll be in touch!");
                    setSelectedNotLaunchedState(null);
                  } catch (e: AnyTS4TryCatchUnknownError) {
                    notifyError('There was a problem recording your email.');
                    logException(e);
                  }
                }}
                render={(props) => (
                  <SafeFormikForm onSubmit={props.handleSubmit}>
                    <FieldControl
                      css={{
                        marginTop: theme.space.xl,
                        marginBottom: theme.space.xl,
                      }}
                      fullWidth
                      name="email"
                    >
                      <FieldInputLabel>Email</FieldInputLabel>
                      <FieldInput />
                      <FieldErrorText />
                    </FieldControl>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={props.isSubmitting}
                      css={{ float: 'right' }}
                    >
                      Let me know
                    </Button>
                  </SafeFormikForm>
                )}
              />
            </Modal>
          </React.Fragment>
        );
      }}
    </Formik>
  );
});
